import React, { useState, useEffect, useContext } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import SourceEmitter from 'libs/emitter';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Layout, Seo } from 'components';

import { appContext } from 'providers/appProvider';

import ContentBlock from 'components/Content/ContentBlock';
import HeadlineBar from 'components/Content/HeadlineBar';
import ColorBlock from 'components/Content/ColorBlock';
import AlertBox from 'components/Content/AlertBox';
import imgSpray from '../../../assets/images/patient-nascobal-spray.png';
import ExternalLink from 'components/ExternalLink';
import DownloadBlock from 'components/DownloadBlock';
import VideoContainer from 'components/VideoContainer';
import CarouselNDHCP from 'components/CarouselNDHCP';
import BrandCTA from 'components/BrandCta';
import { HangingMary } from 'components/Content/HangingMary';

// import imgHero from '../../../assets/images/nutrition-direct-pat-hero-bottles.png';
import step1 from '../../../assets/images/nasal-spray-step1-hcp.png';
import step2 from '../../../assets/images/nasal-spray-step2-hcp.png';
import step3 from '../../../assets/images/nasal-spray-step3-hcp.png';
import bottle1 from '../../../assets/images/bariactiv-bottle1.png';
import bottle2 from '../../../assets/images/bariactiv-bottle2.png';
import logoAsmbs from '../../../assets/images/asmbs-logo.png';
import patSavingHero from '../../../assets/images/why-nutrition-direct-block-hero.png';
import patSavingHeroMobile from '../../../assets/images/nutrition-direct-pat-saving-hero-mobile.png';
import enrollmentHero from '../../../assets/images/nutrition-hcp-enrollment-forms-hero.png';
import enrollmentHeroMobile from '../../../assets/images/nutrition-hcp-enrollment-forms-hero-mobile.png';
import meanImage from '../../../assets/images/vitaminb12-mean-concentration.png';
import imgTick from '../../../assets/images/table-yellow-tick.png';
import imgBottle1 from '../../../assets/images/bottle-icon1.png';
import imgBottle2 from '../../../assets/images/bottle-icon2.png';
// import img85 from '../../../assets/images/patient-85-percent-nd-hcp.png';
// import step1Bullet from '../../../assets/images/step1-bullet.png';
// import step2Bullet from '../../../assets/images/step2-bullet.png';
// import step3Bullet from '../../../assets/images/step3-bullet.png';
import imgPayLittle from '../../../assets/images/nutrition-pay-callout.png';

import videoThumb from '../../../assets/images/nutrition-hcp-logo.png';
// import NDenrollmentPDF from '../../../assets/Nutrition-Direct-Enrollment-Form.pdf';
import NDfaqPDF from '../../../assets/ND-FAQs.pdf';
// import NDfactsPDF from '../../../assets/BariActiv_Nutrition_Facts.pdf';

import NDchewablesPDF from '../../../../static/pdfs/NS-05460_Nutrition_Direct_Chewable_BariActiv_Leave_Behind_-_Digital_.pdf';
import NDtabletsPDF from '../../../../static/pdfs/NS-05461_Nutrition_Direct_Tablet_BariActiv_LeaveBehind_Digital.pdf';
import NDenroll from '../../../../static/pdfs/NS-05705_Nutrition_Direct_Enrollment_Form_Digital.pdf';
import Eprescribe from '../../../../static/pdfs/NS-05456_ePharmacy_Leavebehind_R2_RTP.pdf';

// SASS
import './index.scss';

const IndexPage = props => {
  const [directLinkVideo, setDirectLinkVideo] = useState(false);
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionHcp;
  const metaData = appConfigs?.metaData?.nutritionHcp;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  // tracking
  const calciumTracking = {
    category: 'URL-NutritionFacts',
    action: 'clicked on URL-NutritionFacts',
    label: 'Download Calcium Chew Facts - HCP',
  };
  const multiChewTracking = {
    category: 'URL-NutritionFacts',
    action: 'clicked on URL-NutritionFacts',
    label: 'Download Multi Chew Facts - HCP',
  };
  const ironChewTracking = {
    category: 'URL-NutritionFacts',
    action: 'clicked on URL-NutritionFacts',
    label: 'Download Iron Chew Facts - HCP',
  };
  const calciumChewTracking = {
    category: 'URL-NutritionFacts',
    action: 'clicked on URL-NutritionFacts',
    label: 'Download Calcium Tab Facts - HCP',
  };
  const multiTabTracking = {
    category: 'URL-NutritionFacts',
    action: 'clicked on URL-NutritionFacts',
    label: 'Download Multi Tab Facts - HCP',
  };
  const ironTabTracking = {
    category: 'URL-NutritionFacts',
    action: 'clicked on URL-NutritionFacts',
    label: 'Download Iron Tab Facts - HCP',
  };
  // const enrollmentTracking = {
  //   category: 'Button-Enrollment',
  //   action: 'clicked on Button-Enrollment',
  //   label: 'Download ND Enrollment Form - HCP',
  // };
  const buttonTracking = {
    category: 'Button-FAQs',
    action: 'clicked on Button-FAQs',
    label: 'Download ND FAQs',
  };
  const buttonTracking2 = {
    category: 'Button-copay',
    action: 'clicked on Button-copay',
    label: 'Download Copay card',
  };

  // INITIALIZATIONS
  let Subscription = null;

  const triggerHashChange = () => {
    const currentHash = window?.location.hash.substring(
      1,
      window?.location.hash.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  const setResponseParams = () => {
    const responseObj = window.location.hash
      .substr(1)
      .split('&')
      .map(el => el.split('='))
      .reduce((pre, cur) => {
        pre[cur[0]] = cur[1];
        return pre;
      }, {});
    setDirectLinkVideo(responseObj.video);

    if (responseObj.physicianLocator) {
      SourceEmitter.emit('SpecialistLocatorTrigger', 'open');
    }
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);

    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    setResponseParams();

    return () => {
      // eslint-disable-next-line no-unused-expressions
      window?.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const renderPage = () => {
    return (
      <div id="nutrition-hcp-home">
        <ContentBlock>
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <div>
              <CarouselNDHCP />
              <Row between="xs">
                <Col xs={12} md={6}>
                  <BrandCTA
                    ExternalLink
                    href={NDenroll}
                    LinkCaption="DOWNLOAD PATIENT ENROLLMENT FORM"
                  >
                    DOWNLOAD PATIENT ENROLLMENT FORM
                  </BrandCTA>
                </Col>
                <Col xs={12} md={6}>
                  <BrandCTA
                    ExternalLink
                    href={Eprescribe}
                    LinkCaption="E-PRESCRIBE ND"
                  >
                    E-PRESCRIBE NUTRITION DIRECT
                    <span className="brand-trade-mark">&trade;</span>
                  </BrandCTA>
                </Col>
              </Row>
            </div>
          </Grid>
        </ContentBlock>


        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="nutrition-hcp-home">
      <Seo {...metaData} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default IndexPage;
