import React from 'react';
import PropTypes from 'prop-types';
import VideoModal from '../VideoModal/';
import cx from 'classnames';
import SourceEmitter from '../../libs/emitter';

import './index.scss';

class VideoContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.directLink === true && this.props.directLink === false) {
      this.toggle('modalOpen');
      const val = !this.state.modalOpen;
      this.setState({ modalOpen: val });
      this.stopVideo();
    }
  }

  componentWillMount() {
    SourceEmitter.addListener(
      `onVideoOpenEvent-${this.props.videoId}`,
      data => {
        this.setState({
          modalOpen: true,
        });
      },
    );
  }

  toggle(key) {
    return () => {
      const val = !this.state[key];
      this.setState({ [key]: val });
      this.stopVideo();
    };
  }

  stopVideo() {
    const el = document.getElementById(this.props.videoId);
    const iframe = el.querySelector('iframe');
    const video = el.querySelector('video');
    if (iframe !== null) {
      const iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
    if (video !== null) {
      video.pause();
    }
  }

  render() {
    const {
      poster,
      url,
      smallThumb,
      className,
      videoId,
      carousel,
      dlURL,
      video,
    } = this.props;

    const imgContent = carousel ? (
      <div className="video-btn-invisible" />
    ) : (
      <img
        className={cx({
          'video-smaller': true,
          'video-small-thumb': smallThumb,
          [className]: className,
        })}
        src={poster}
        onClick={this.toggle('modalOpen')}
      />
    );

    return (
      <div>
        {imgContent}
        <VideoModal
          {...this.state}
          toggle={this.toggle}
          url={url}
          videoId={videoId}
          dlURL={dlURL}
          video={video}
        />
      </div>
    );
  }
}

VideoContainer.propTypes = {
  children: PropTypes.node,
  poster: PropTypes.string,
  url: PropTypes.string,
  active: PropTypes.bool,
  smallThumb: PropTypes.bool,
  className: PropTypes.string,
  directLink: PropTypes.bool,
  videoId: PropTypes.string,
};

export default VideoContainer;
