import React from 'react';
import cx from 'classnames';
import './index.scss';

class HeadlineBar extends React.Component {
  render() {
    return (
      <div
        id={this.props.id}
        className={cx(this.props.className, {
          'headline-bar': true,
          'headline-blue': this.props.blue,
        })}
      >
        <h1>{this.props.children}</h1>
      </div>
    );
  }
}

export default HeadlineBar;
