import React from 'react';
import { withPrefix } from 'gatsby-link';
import { Row, Col } from 'react-flexbox-grid';
import ActiveLink from '../ActiveLink';
import './db.scss';

const DownloadBlock = props => {
  const category = props.VideoLink ? 'Video Watch Button' : 'PDF Download';

  const ThumbnailImage = () => (
    <img
      src={require(`../../../assets/images/${props.ThumbnailImage}`)}
      className="pdf-thumbnail"
    />
  );

  const ButtonLink = () => (
    <ActiveLink
      DownloadLink
      DeadLink={props.DeadLink === 'true'}
      VideoLink={props.VideoLink === true}
      Direct={props.Direct === true}
      VideoId={props.VideoId ? props.VideoId : ''}
      Link={props.Link ? props.Link : ''}
      EnableActiveClass={false}
      to={withPrefix(`/pdfs/${props.DownloadFile}`)}
      EventObject={{
        category: category,
        action: 'Click',
        label: props.LinkCaption,
      }}
      className={props.className}
    >
      {props.LinkCaption}
    </ActiveLink>
  );

  const VideoLink = () => (
    <ActiveLink
      DownloadLink
      DeadLink={props.DeadLink === 'true'}
      VideoLink={props.VideoLink === true}
      Direct={props.Direct === true}
      VideoId={props.VideoId ? props.VideoId : ''}
      Link={props.Link ? props.Link : ''}
      EnableActiveClass={false}
      to={withPrefix(`/pdfs/${props.DownloadFile}`)}
      EventObject={{
        category: category,
        action: 'Click',
        label: props.LinkCaption,
      }}
      className="pdf-thumbnail"
    >
      <ThumbnailImage />
    </ActiveLink>
  );

  const DisplayImage = () => {
    return props.VideoLink ? (
      <VideoLink />
    ) : props.ThumbnailImageLink ? (
      <a
        href={withPrefix(`/pdfs/${props.DownloadFile}`)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ThumbnailImage />
      </a>
    ) : (
      <ThumbnailImage />
    );
  };

  return (
    <div className="db-container">
      <Row>
        <Col xs={12} md={8}>
          <div className="db-inner-wrapper">
            <span className="copy-matter">{props.children}</span>
            <ButtonLink />
            {/* returns secondary button if secondary flag is set dont hate me */}
            {(() => {
              if (props.secondary === true) {
                return (
                  <ActiveLink
                    DownloadLink
                    DeadLink={props.DeadLink === 'true'}
                    EnableActiveClass={false}
                    to={withPrefix(`/pdfs/${props.secondaryDownloadFile}`)}
                    EventObject={{
                      category: 'PDF Download',
                      action: 'Click',
                      label: props.secondaryLinkCaption,
                    }}
                    className={`${props.secondaryClassName} second`}
                  >
                    {props.secondaryLinkCaption}
                  </ActiveLink>
                );
              }
            })()}
            {(() => {
              if (props.footnote === true) {
                return (
                  <p
                    className="footnote top-spacer no-bold ml-3"
                    dangerouslySetInnerHTML={{ __html: props.footnoteText }}
                  />
                );
              }
            })()}
          </div>
        </Col>
        <Col xs={12} md={4}>
          <DisplayImage />
        </Col>
      </Row>
    </div>
  );
};

export default DownloadBlock;
