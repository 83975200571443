import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-awesome-modal';
import { Row, Col } from 'react-flexbox-grid';
import WistiaVideo from '../WistiaVideo';

import './index.scss';

const VideoModal = ({ modalOpen, toggle, url, videoId, dlURL, video }) => (
  <Modal
    visible={modalOpen}
    effect="fadeInRight"
    onClickAway={toggle('modalOpen')}
  >
    <Row className="video-modal">
      <Col xs={12}>
        <div className="close-container" onClick={toggle('modalOpen')}>
          <div className="leftright" />
          <div className="rightleft" />
          <label className="close">close</label>
        </div>
        <div className="video-modal-content">
          <div id={videoId} className="video-modal-container">
            <WistiaVideo
              wistiaID={video?.wistiaID}
              inModal={true}
            />
          </div>
        </div>
      </Col>
    </Row>
  </Modal>
);

VideoModal.propTypes = {
  children: PropTypes.node,
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  url: PropTypes.string,
  videoId: PropTypes.string,
};

export default VideoModal;
